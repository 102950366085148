<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    :label="$t('time.calendar.leave_type')"
    :loading="loading || fetching"
    :items="items"
    :menu-props="{ offsetY: true, overflowY: true }"
    item-value="leavePolicy.leaveType.id"
    item-text="leavePolicy.leaveType.name"
    @input="$emit('input', $event)"
  >
    <template #item="{ item }">
      <v-list-item-avatar size="32" :color="item.leavePolicy.leaveType.color" class="mr-2">
        <v-icon size="20">{{ item.leavePolicy.leaveType.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.leavePolicy.leaveType.name }}
        </v-list-item-title>
        <v-list-item-subtitle class="caption">
          {{ $t("leave.accruals.used") }}: {{ item.used.toFixed(2) }} / {{ $t("leave.accruals.balance") }}:
          {{ item.remainingBalance.toFixed(2) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-05 17:31:57
   * @modify date 2021-01-05 17:31:57
   * @desc LeaveTypePicker
   */
  import gql from "graphql-tag";

  export default {
    name: "LeaveAccrualPicker",
    inheritAttrs: false,
    props: {
      value: [String, Object],
      loading: Boolean,
      filterItems: Function,
      employee: String,
      beginDate: {
        type: String,
        default() {
          return this.$moment().format("YYYY-MM-DD");
        }
      }
    },
    data: vm => ({
      fetching: false,
      items: []
    }),
    watch: {
      employee: {
        immediate: true,
        handler(employee) {
          if (employee) {
            this.fetchItems();
          } else {
            this.items = [];
          }
        }
      }
    },
    methods: {
      fetchItems() {
        const options = {
          query: gql`
            query leaveAccruals($employee: ID!, $beginDate: String!, $daily: Boolean) {
              leaveAccruals(employee: $employee, beginDate: $beginDate, daily: $daily) {
                remainingBalance
                previousBalance
                used
                leavePolicy {
                  accrualCount
                  accrualCalculatedCount
                  leaveType {
                    id
                    name
                    color
                    icon
                    weight
                    applicable {
                      hasTravel
                    }
                  }
                }
              }
            }
          `,
          variables: {
            employee: this.employee,
            beginDate: this.beginDate,
            daily: null
          },
          fetchPolicy: "no-cache"
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data: { error, leaveAccruals }, errors }) => {
            if (!error && !errors) {
              let items = leaveAccruals.filter(leaveAccrual => leaveAccrual.remainingBalance > 0);
              if (this.filterItems && typeof this.filterItems === "function") {
                items = items.filter(this.filterItems);
              }
              this.items = items;
            }
          })
          .catch(console.error)
          .finally(() => (this.fetching = false));
      }
    }
  };
</script>
